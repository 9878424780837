import abi from "../assets/abis/AuctionPlatformABI.json";
import { getGlobalState, setGlobalState } from "../store/index";

import Web3 from "web3";
// All Contracts' ABI
import AuctionPlatformABI from "../assets/abis/v4/NewAuction.json";
import TetherToken from "../assets/abis/v4/CUSDT.json";
import BidCoin from "../assets/abis/v4/BidCoin.json";
import PresaleContract from "../assets/abis/v4/Presale.json";
import StakingContract from "../assets/abis/v4/StakingContract.json";
import GovernanceContract from "../assets/abis/v4/GovernanceContract.json";
import Treasury from "../assets/abis/v4/Treasury.json";
import AuctionFactory from "../assets/abis/v4/AuctionFactory.json";
import NewAuction from "../assets/abis/v4/NewAuction.json";
import { toast } from "react-toastify";

const { ethereum } = window;
const ContractAbi = abi.abi;
let tx;

const getEthereumContract = async () => {
  const connectedAccount = getGlobalState("connectedAccount");

  if (connectedAccount) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(ContractAddress, ContractAbi, signer);

    return contract;
  } else {
    return getGlobalState("contract");
  }
};
const initAllContracts = async () => {
  try {
    const web3 = new Web3(Web3.givenProvider || process.env.REACT_APP_RPC_URL);
    setGlobalState("web3", web3);

    const networkId = await web3.eth.net.getId();
    if (networkId !== 1337) {
      console.log(`Unexpected networkId: ${networkId}. Expected 1337.`);
      // return;
    }

    const deployedContracts = {
      tetherToken: new web3.eth.Contract(
        TetherToken.abi,
        process.env.REACT_APP_TETHERTOKEN_ADDRESS
      ),
      bidCoin: new web3.eth.Contract(
        BidCoin.abi,
        process.env.REACT_APP_BIDCOIN_ADDRESS
      ),
      presale: new web3.eth.Contract(
        PresaleContract.abi,
        process.env.REACT_APP_PRESALE_ADDRESS
      ),
      governanceContract: new web3.eth.Contract(
        GovernanceContract.abi,
        process.env.REACT_APP_GOVERNANCECONTRACT_ADDRESS
      ),
      treasury: new web3.eth.Contract(
        Treasury.abi,
        process.env.REACT_APP_TREASURY_ADDRESS
      ),
      stakingContract: new web3.eth.Contract(
        StakingContract.abi,
        process.env.REACT_APP_STAKINGCONTRACT_ADDRESS
      ),
      auctionFactory: new web3.eth.Contract(
        AuctionFactory.abi,
        process.env.REACT_APP_AUCTIONFACTORY_ADDRESS
      ),
      newAuction: new web3.eth.Contract(
        NewAuction.abi,
        process.env.REACT_APP_NEWAUCTION_ADDRESS
      ),
    };

    setGlobalState("contracts", deployedContracts);
  } catch (error) {
    console.error("Error initializing web3:", error);
  }
};

const isWalletConnected = async () => {
  try {
    if (!ethereum) return alert("Please install Metamask");
    const accounts = await ethereum.request({ method: "eth_accounts" });
    setGlobalState("connectedAccount", accounts[0]?.toLowerCase());

    window.ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });

    window.ethereum.on("accountsChanged", async () => {
      setGlobalState("connectedAccount", accounts[0]?.toLowerCase());
      await isWalletConnected();
    });

    if (accounts.length) {
      setGlobalState("connectedAccount", accounts[0]?.toLowerCase());
      console.log("Wallet already connected, fetching user data.");
      await fetchUserData();
    } else {
      alert("Please connect wallet.");
      connectWallet();
      console.log("No accounts found.");
    }
  } catch (error) {
    toast.error(error);
  }
};

const connectWallet = async () => {
  try {
    if (!ethereum) return alert("Please install Metamask");
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    setGlobalState("connectedAccount", accounts[0]?.toLowerCase());
    await fetchUserData();
  } catch (error) {
    toast.error(error);
  }
};

const updatePrice = async ({ tokenId, price }) => {
  try {
    if (!ethereum) return alert("Please install Metamask");
    const connectedAccount = getGlobalState("connectedAccount");
    const contract = await getEthereumContract();
    tx = await contract.changePrice(tokenId, toWei(price), {
      from: connectedAccount,
    });
    await tx.wait();
    await loadAuctions();
  } catch (error) {
    toast.error(error);
  }
};

const loadAuction = async (id) => {
  try {
    if (!ethereum) return alert("Please install Metamask");
    const contract = await getEthereumContract();
    const auction = await contract.getAuction(id);
    setGlobalState("auction", structuredAuctions([auction])[0]);
  } catch (error) {
    toast.error(error);
  }
};

const fetchUserData = async () => {
  try {
    if (!ethereum) return alert("Please install Metamask");
    const connectedAccount = getGlobalState("connectedAccount");
    const contracts = getGlobalState("contracts");

    if (connectedAccount && contracts) {
      let userData = {};
      userData.address = connectedAccount;

      // Fetch BidCoin balance
      if (contracts.bidCoin && contracts.bidCoin.methods) {
        const balance = await contracts.bidCoin.methods
          .balanceOf(connectedAccount)
          .call();
        userData.bidBalance = Number(
          ethers.utils.formatUnits(balance, 18)
        ).toFixed(2);
      }

      // Fetch USDT balance
      if (contracts.tetherToken && contracts.tetherToken.methods) {
        const usdtBalance = await contracts.tetherToken.methods
          .balanceOf(connectedAccount)
          .call();
        userData.usdtBalance = Number(
          ethers.utils.formatUnits(usdtBalance, 18)
        ).toFixed(2);
      }

      // Fetch staking data
      if (contracts.stakingContract && contracts.stakingContract.methods) {
        const stakerInfo = await contracts.stakingContract.methods
          .stakers(connectedAccount)
          .call();
        const totalStakedContract = await contracts.stakingContract.methods
          .totalStaked()
          .call();

        userData.totalStakedContract = Number(
          ethers.utils.formatUnits(totalStakedContract, 18)
        ).toFixed(2);
        userData.totalStaked = Number(
          ethers.utils.formatUnits(stakerInfo.stakedAmount, 18)
        ).toFixed(2);
        userData.totalReward = Number(
          ethers.utils.formatUnits(stakerInfo.rewardAmount, 18)
        ).toFixed(2);
      }

      // Fetch circulating supply
      if (contracts.bidCoin && contracts.bidCoin.methods) {
        const circulatingSupply = await contracts.bidCoin.methods
          .circulatingSupply()
          .call();
        userData.totalCirculatingSupply = Number(
          ethers.utils.formatUnits(circulatingSupply, 18)
        ).toFixed(2);
      }

      // Fetch presale data
      if (contracts.presale && contracts.presale.methods) {
        const isActive = await contracts.presale.methods
          .isPresaleActive()
          .call();
        const balance = await contracts.presale.methods
          .presalePurchases(connectedAccount)
          .call();
        const burntAmount = await contracts.presale.methods
          .burntAmount(connectedAccount)
          .call();
        const initialStakingLimit = await contracts.presale.methods
          .initialStakingLimit()
          .call();
        const burnToStakeRatio = await contracts.presale.methods
          .burnToStakeRatio()
          .call();
        const maxStakingLimit = await contracts.presale.methods
          .maxStakingLimit()
          .call();
        const maxTokensPerUser = await contracts.presale.methods
          .maxTokensPerUser()
          .call();
        const burnLimit = await contracts.presale.methods.burnLimit().call();
        const discount = await contracts.presale.methods.discount().call();
        const rate = await contracts.presale.methods.rate().call();

        userData.presaleData = {
          isActive: isActive,
          discount: Number(discount).toFixed(2),
          rate: Number(rate).toFixed(2),
          balance: Number(ethers.utils.formatUnits(balance, 18)).toFixed(2),
          burntAmount: Number(
            ethers.utils.formatUnits(burntAmount, 18)
          ).toFixed(2),
          initialStakingLimit: Number(initialStakingLimit).toFixed(2),
          burnToStakeRatio: Number(burnToStakeRatio).toFixed(2),
          maxStakingLimit: Number(maxStakingLimit).toFixed(2),
          maxTokensPerUser: Number(
            ethers.utils.formatUnits(maxTokensPerUser, 18)
          ).toFixed(2),
          burnLimit: Number(ethers.utils.formatUnits(burnLimit, 18)).toFixed(2),
        };
      }

      setGlobalState("currentUser", userData);
      console.log("User data fetched successfully.");

      console.log(userData);
    } else {
      console.error("Connected account or contracts not available");
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    toast.error("Failed to fetch user data");
  }
};
export {
  isWalletConnected,
  connectWallet,
  initAllContracts,
  loadAuction,
  fetchUserData,
  updatePrice,
};
