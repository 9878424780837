

import React, { Suspense, useEffect, lazy } from "react";
import { Route, Navigate, Routes, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { initAllContracts } from "../Services/BlockChainService";
import AppFooter from "./AppFooter";
import AppHeader from "./AppHeader";
import LoadingScreen from "./LoadingScreen";
import ErrorBoundary from "./ErrorBoundary";
import { LoadingProvider, useLoading } from "./LoadingContext";
import HowItWorksScreen from "./HowItWorks";


// Lazy load all components
const HomePage = lazy(() => import("./HomePage"));
const AuctionsUI = lazy(() => import("./AuctionsUI"));
const AuctionDetails = lazy(() => import("./AuctionDetailsUI"));
const CreateAuction = lazy(() => import("./CreateAuction"));
const GovernanceVoting = lazy(() => import("./GovernanceUI"));
const UserProfileRewards = lazy(() => import("./UserProfile"));
const NotificationCenter = lazy(() => import("./NotificationCenter"));
const StakingInterface = lazy(() => import("./StakingUI"));
const PresaleUI = lazy(() => import("./PresaleUI"));
const ReferralCommission = lazy(() => import("./ReferralUI"));
const TermsAndConditionsPage = lazy(() => import("./TermsAndCondtions"));
const LegalRefundPage = lazy(() => import("./LegalRefund"));
const RefundPage = lazy(() => import("./Refund"));



const CustomAppRouter = () => {
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    const initContracts = async () => {
      setIsLoading(true);
      // await initAllContracts();
      setIsLoading(false);
    };
    initContracts();
  }, [setIsLoading]);
  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen bg-center subpixel-antialiased bg-gray-900 text-white mt-24">
      <AppHeader />
      <ErrorBoundary>
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/HomePage" element={<HomePage />} />
            <Route path="/Auctions" element={<AuctionsUI />} />
            <Route path="/AuctionDetails" element={<AuctionDetails />} />
            <Route path="/CreateAuction" element={<CreateAuction />} />
            <Route path="/GovernanceVoting" element={<GovernanceVoting />} />
            <Route path="/HowItWorks" element={<HowItWorksScreen />} />
            <Route path="/profile" element={<UserProfileRewards />} />
            <Route path="/notifications" element={<NotificationCenter />} />
            <Route path="/StakingInterface" element={<StakingInterface />} />
            <Route path="/TermAndConditions" element={<TermsAndConditionsPage />} />
            <Route path="/legalrefund" element={<LegalRefundPage />} />
            <Route path="/refund" element={<RefundPage />} />
            {/* <Route path="/Presale" element={<PresaleUI />} /> */}
            <Route path="/ReferralUI" element={<ReferralCommission />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
      <AppFooter />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};
export default CustomAppRouter;

