import React from "react";
import { Loader2 } from "lucide-react";

const LoadingScreen = () => {
  return (
    <div className="fixed inset-0 bg-gray-900 flex flex-col items-center justify-center z-50">
      <div className="text-blue-500 animate-spin mb-4">
        <Loader2 size={48} />
      </div>
      <h2 className="text-2xl font-bold text-white mb-2">Loading BidCoin</h2>
      <p className="text-gray-400 text-center max-w-md px-4">
        Preparing your decentralized auction experience...
      </p>
      <div className="mt-8 w-64 bg-gray-700 rounded-full h-2">
        <div
          className="bg-blue-500 h-2 rounded-full animate-pulse"
          style={{ width: "75%" }}
        ></div>
      </div>
      <div className="mt-4 flex space-x-2">
        {[1, 2, 3].map((i) => (
          <div
            key={i}
            className="w-3 h-3 bg-blue-500 rounded-full animate-bounce"
            style={{ animationDelay: `${i * 0.2}s` }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default LoadingScreen;
