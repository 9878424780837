import React from "react";
import CustomAppRouter from "./CustomAppRouter";
import { BrowserRouter } from "react-router-dom";
import { LoadingProvider } from "./LoadingContext";

const AppRouter = () => {
  return (
    <div className="App">
      <LoadingProvider>
        <CustomAppRouter />
      </LoadingProvider>
    </div>
  );
};

export default AppRouter;
