import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";

const PasswordInput = ({ id, name, value, onChange, placeholder }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative">
      <input
        id={id}
        name={name}
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        required
        className="w-full px-3 py-2 pr-10 rounded-md  bg-gray-700 text-white border border-gray-800"
        placeholder={placeholder}
      />
      <button
        type="button"
        className="absolute inset-y-0 right-0 pr-3 flex items-center"
        onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
      </button>
    </div>
  );
};

const FormContainer = ({ children, title, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div className="bg-gray-800 rounded-lg p-6 sm:p-8 w-full max-w-md relative shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl font-bold"
          style={{ color: "var(--secondary-text)" }}>
          ×
        </button>
        <h2
          className="text-2xl font-bold mb-4"
          style={{ color: "var(--primary-text)" }}>
          {title}
        </h2>
        {children}
      </div>
    </div>
  );
};

const LoginForm = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Login form submitted:", formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <FormContainer title="Log In" onClose={onClose}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="login-email"
            className="block mb-1"
            style={{ color: "var(--primary-text)" }}>
            Email
          </label>
          <input
            id="login-email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 rounded-md  bg-gray-700 text-white border border-gray-800"
            placeholder="Enter your email"
          />
        </div>
        <div>
          <label
            htmlFor="login-password"
            className="block mb-1"
            style={{ color: "var(--primary-text)" }}>
            Password
          </label>
          <PasswordInput
            id="login-password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            placeholder="Enter your password"
          />
        </div>
        <div className="flex justify-between items-center">
          <label className="flex items-center">
            <input type="checkbox" className="mr-2" />
            <span style={{ color: "var(--secondary-text)" }}>Remember me</span>
          </label>
          <a href="#" style={{ color: "var(--link-text)" }}>
            Forgot password?
          </a>
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 rounded bg-green-500 text-white  hover:bg-green-600">
          Log In
        </button>
      </form>
    </FormContainer>
  );
};
export default LoginForm;
